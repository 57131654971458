import { Fragment, useEffect, useState } from 'react';
import { useAsync, useToggle } from 'react-use';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Cell,
  ColorPickerItem,
  Conditional,
  Dropdown,
  Flex,
  Grid,
  Icon,
  Line,
  productColorsMap,
  TextArea,
  TextField,
  Tooltip,
  Typography,
} from 'gantri-components';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { DetailsPanel } from '../../../common/details-panel';
import { JobsDetailsPanelProps } from './jobs-details-panel.types';
import { jobsApi } from '../../../../api';
import { InventoryRequest, Job } from '../../../../api/jobs/jobs.types';
import { MachineType } from '../../../../api/machines/machines.types';
import {
  StyledJobDetailsCell,
  StyledStockWithPriority,
} from './jobs-details-panel.styles';
import {
  addJobTypeOptionsForJobStep,
  jobTemplateTypeOptionsForJobStep,
  jobTypeToIconTypeMap,
  primaryStepsForJobs,
} from '../../../../constants/jobs';
import { RFIDCell } from '../../../common/custom-data-cells/rfid-data-cell';
import {
  StyledDivider,
  StyledInlineContainer,
} from '../../../../assets/styles/common';
import { formatDate } from '../../../../helpers/formatter';
import routePaths from '../../../../config/route-paths';
import Timeline from '../../../common/timeline';
import TimelineItem from '../../../common/timeline/base-timeline-item';
import StatusDataCell from '../../../common/custom-data-cells/status-data-cell';
import { useJobMenu } from '../../../../hooks/use-job-menu';
import { jobStatuses, JobType } from '../../../../constants/options';
import { useNotification } from '../../../../hooks/useNotification';
import InstructionDataCell from '../../../common/custom-data-cells/instruction-data-cell';
import { StyledTooltipTypography } from '../../../common/custom-data-cells/custom-data-cells-styles';
import { prepareFailureReasons } from '../../../../helpers/fail-reason';
import StackedContainer from '../../../common/stacked-container';
import placeholderLargeImgSrc from '../../../../assets/images/placeholders/part-large.png';
import ThumbnailLink from '../../../common/thumbnail-link';
import { JobDetailsPanelFooter } from './components/job-details-panel-footer';
import { useJobComplete, useJobStart } from '../../../../pages/jobs/hooks';
import { MachineDataCell } from '../../../../pages/jobs/components';
import {
  dataAttrLinkedTo,
  JOB_DETAILS_PANEL_LINKED_TO,
} from './jobs-details-panel.constants';
import { elementInDOM } from '../../../../helpers/dom';
import { MachineIssues } from './components/machine-issues';
import { JobDetails } from '../../../../api/jobs/routes/get-job-details/get-job-details.types';
import { MinorVersionInfo } from '../../../minor-version-info';
import { MachineTypeSection } from './components/machine-type-section';
import { LONG_FORMAT } from '../../../../constants/dates';
import { useThumbnailZoomModal } from '../../../../pages/jobs/components/modals/common/job-checklist/components/advanced-checklist/components/advanced-checklist-reason/use-thumbnail-zoom-modal';
import { CellJobAttempt } from '../../../../pages/part/components/part-jobs/components/cell-job-attempt';
import GcodeDataCell from '../../../common/custom-data-cells/gcode-data-cell';
import { InventoryDataCell } from '../../../common/custom-data-cells/inventory-data-cell';
import { StyledAnchor } from '../../../common/styled-anchor';
import { jobsDetailsPanelAtoms } from './jobs-details-panel.atoms';
import { NotApplicableText } from '../../../common/not-applicable-text';
import { CellAssignedTo } from './components/cell-assigned-to';
import { getJobInfo } from '../../../../pages/jobs/helpers/get-job-info';
import { filterInvalidInventoryRequests } from '../../../../helpers/filter-invalid-inventory-requests';
import { unpaintedColor } from '../../../../constants/colors';
import { ShortProductSummary } from '../../../common/short-product-summary';
import {
  GCode,
  JobInstructions,
} from '../../../../api/products/routes/fetch-product-part-templates/fetch-product-part-templates.types';

export const JobsDetailsPanel = (props: JobsDetailsPanelProps) => {
  const { onRefresh, userNames } = props;

  const jobIdInDetailsPanel = useRecoilValue(jobsDetailsPanelAtoms.activeJobId);
  const resetJobIdInDetailsPanel = useResetRecoilState(
    jobsDetailsPanelAtoms.activeJobId,
  );
  const [viewInEditMode, setViewInEditMode] = useRecoilState(
    jobsDetailsPanelAtoms.viewInEditMode,
  );

  const showDetailsPanel = !!jobIdInDetailsPanel;

  const [job, setJob] = useState<JobDetails>();

  const { isFloatingPartJob, isNonProductJob, isProductJob, isRepairJob } =
    getJobInfo(job);

  const toggleEditingMode = () => {
    setViewInEditMode(!viewInEditMode);
  };

  const [editingNotes, setEditingNotes] = useToggle(false);
  const { notify, notifyAxiosError, onInterceptRequest } = useNotification();

  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [innerDuration, setInnerDuration] = useState<number>();
  const [jobStep, setJobStep] = useState('');
  const [jobType, setJobType] = useState('');
  const jobInstructionPlaceholder: JobInstructions = { files: [], text: '' };
  const [jobInstruction, setJobInstruction] = useState<JobInstructions>(
    jobInstructionPlaceholder,
  );
  const gcodePlaceholder: GCode = { fileName: '', url: '' };
  const [jobGcode, setJobGcode] = useState<GCode>(gcodePlaceholder);
  const [jobInventory, setJobInventory] = useState<InventoryRequest[]>([]);
  const [machineType, setMachineType] = useState<MachineType>();
  const [assignedTo, setAssignedTo] = useState<number>();

  const fetchJobDetails = async () => {
    if (!!jobIdInDetailsPanel) {
      const response = await jobsApi
        .getJobDetails(jobIdInDetailsPanel)
        .then((data) => {
          return data;
        });

      setJob(response.success ? response.job : null);
      setName(response.job.description);
      setInnerDuration(response.job.duration);
      setJobStep(response.job.step);
      setJobType(response.job.type);
      setMachineType(response.job.machineType);
      setNotes(response.job.notes);
      setJobInstruction(response.job.instruction || jobInstructionPlaceholder);
      setJobGcode(response.job.gcode || gcodePlaceholder);
      setJobInventory(response.job.inventoryRequests ?? []);
      setAssignedTo(response.job.assignedTo?.userId);
    }
  };

  const handleRefresh = async () => {
    onRefresh();
    await fetchJobDetails();
  };

  const { menuOptions } = useJobMenu({
    job,
    onRefresh: handleRefresh,
  });

  const onClose = () => {
    setJob(null);
    resetJobIdInDetailsPanel();
    setViewInEditMode(false);
  };

  const startJob = useJobStart({
    onStartEnd: handleRefresh,
  });

  const completeJob = useJobComplete({
    onCompleteEnd: handleRefresh,
  });

  const updateJob = async () => {
    await onInterceptRequest(async () => {
      try {
        const updatedJobData = {
          description: name,
          duration: innerDuration,
          instruction: jobInstruction,
          inventoryRequests: filterInvalidInventoryRequests(jobInventory),
          notes,
          step: jobStep,
          type: jobType,
        } satisfies Partial<Job>;

        if (isProductJob || isRepairJob) {
          const { data } = await jobsApi.updateJob({
            ...updatedJobData,
            jobId: jobIdInDetailsPanel,
          });

          notify(data.notice);
        } else {
          const { data } = await jobsApi.updateNonProductJob(
            jobIdInDetailsPanel,
            {
              ...updatedJobData,
              assignedTo,
            },
          );

          notify(data.notice);
        }

        toggleEditingMode();
        setEditingNotes(false);
        await handleRefresh();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'An error occurred updating job.',
        });
      }
    });
  };

  const updateNotes = async () => {
    await onInterceptRequest(async () => {
      try {
        const response = await jobsApi.updateJob({
          jobId: jobIdInDetailsPanel,
          notes,
        });

        notify(response.data.notice);
        setEditingNotes(false);
        await fetchJobDetails();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'An error occurred updating job notes.',
        });
      }
    });
  };

  const onAction = async () => {
    if (job.status === jobStatuses.ready) {
      await startJob(job.id);
    } else if (job.status === jobStatuses.inProgress) {
      await completeJob(job.id);
    }
  };

  const [showThumbnailModal] = useThumbnailZoomModal(
    {
      heading: job?.part?.name,
      src: job?.part?.thumbnail,
      width: '55rem',
    },
    [job?.part?.thumbnail],
  );

  const showPartThumbnailModal = () => {
    if (job?.part?.thumbnail) {
      showThumbnailModal();
    }
  };

  const partColorCode = job?.part?.color;
  const disableSaveBtn = !name || !jobType;

  useEffect(() => {
    const trackDocumentClicks = (event: MouseEvent) => {
      const element = event.target as HTMLElement;
      // Added to resolve issues with interactions of additional overlays triggered within JobsDetailsPanel closing the entire panel
      const isValidElement = !/^body$/i.test(element.tagName);

      const linkedToDetailsPanel = element.closest(
        `[data-linked-to="${JOB_DETAILS_PANEL_LINKED_TO}"]`,
      );

      const linkedToModal = element.closest('[data-modal-wrapper]');

      const inDOM = elementInDOM(element);

      if (isValidElement && !linkedToDetailsPanel && !linkedToModal && inDOM) {
        onClose();
      }
    };

    document.addEventListener('click', trackDocumentClicks);

    return () => {
      return document.removeEventListener('click', trackDocumentClicks);
    };
  }, []);

  useAsync(async () => {
    if (showDetailsPanel) {
      await onInterceptRequest(fetchJobDetails);
    }
  }, [jobIdInDetailsPanel, showDetailsPanel]);

  return (
    <DetailsPanel
      dataAttrs={{ [dataAttrLinkedTo]: JOB_DETAILS_PANEL_LINKED_TO }}
      footer={<JobDetailsPanelFooter />}
      menu={menuOptions}
      open={showDetailsPanel}
      title={viewInEditMode ? 'Edit job details' : 'Job details'}
      onClose={onClose}
    >
      {!!job && (
        <Grid
          columnGap="3.4rem"
          columns={{ lg: 1, md: 2, sm: 1 }}
          rowGap="2.5rem"
        >
          {[jobStatuses.inProgress, jobStatuses.ready].some((status) => {
            return status === job.status;
          }) && (
            <Cell width={{ lg: 1, md: 2, sm: 1 }}>
              <Grid columnGap="x" columns={2} rowGap="3x">
                <Conditional condition={viewInEditMode}>
                  <Tooltip
                    description={
                      disableSaveBtn && 'Name and Type are required fields.'
                    }
                    position="left"
                  >
                    <Button
                      disabled={disableSaveBtn}
                      minWidth="4rem"
                      text="Save"
                      variant="secondary"
                      width="100%"
                      onClick={updateJob}
                    />
                  </Tooltip>

                  <Button
                    minWidth="4rem"
                    text="Cancel"
                    onClick={toggleEditingMode}
                  />

                  <Cell width={2}>
                    <Line />
                  </Cell>
                </Conditional>

                <Cell width={2}>
                  <Button
                    minWidth="10rem"
                    text={
                      job.status === jobStatuses.inProgress
                        ? 'Complete'
                        : 'Start'
                    }
                    variant="secondary"
                    onClick={onAction}
                  />
                </Cell>
              </Grid>
            </Cell>
          )}

          <StyledJobDetailsCell>
            <Typography text="Name" textStyle="bold" />
            <div>
              {viewInEditMode ? (
                <TextField
                  placeholder="Job Name"
                  value={name}
                  onTextChange={setName}
                />
              ) : (
                <Typography
                  icon={
                    !!job.attentions?.length && (
                      <Icon color="alert" left="4px" name="view:eye_open" />
                    )
                  }
                  iconPosition="right"
                  text={job.description}
                />
              )}

              {!!job.attentions?.length && (
                <StackedContainer gap="8px" marginTop="1rem">
                  {job.attentions.map((attention, index) => {
                    return (
                      <Typography
                        key={index}
                        color="alert"
                        text={attention.description}
                      />
                    );
                  })}
                </StackedContainer>
              )}
            </div>
          </StyledJobDetailsCell>
          <StyledJobDetailsCell>
            <Typography text="Status" textStyle="bold" />
            <span>
              <StatusDataCell {...job} hideTooltip />
              <Conditional condition={!!job.statusInfo?.rejected}>
                <Box paddingTop="x">
                  <Typography
                    color="alert"
                    display="inline"
                    text={job.statusInfo?.rejected?.reason}
                  />
                  <Typography
                    display="inline"
                    text={` - ${job.statusInfo?.rejected?.reasonDetails} `}
                  />
                  <Typography
                    display="inline"
                    text={formatDate(
                      job.statusInfo?.rejected?.date,
                      LONG_FORMAT,
                    )}
                  />
                  <Conditional condition={!!job.statusInfo?.rejected?.user}>
                    <Typography display="inline" text=" by " />
                    <Link to={`/users/${job.statusInfo?.rejected?.user?.id}`}>
                      <Typography
                        color="link"
                        decoration="underline"
                        display="inline"
                        text={[
                          job.statusInfo?.rejected?.user?.firstName,
                          job.statusInfo?.rejected?.user?.lastName,
                        ]
                          .filter(Boolean)
                          .join(' ')}
                      />
                    </Link>
                  </Conditional>
                </Box>
              </Conditional>

              {!!job.failedReason && (
                <StyledInlineContainer style={{ marginTop: '1rem' }}>
                  <Conditional condition={!!job.failedReason.reason}>
                    {prepareFailureReasons(job.failedReason).map(
                      ({ code, details, label }) => {
                        return (
                          <Fragment key={code}>
                            <Typography
                              color="alert"
                              display="inline"
                              text={label}
                            />
                            <Conditional condition={!!details}>
                              <Typography
                                display="inline"
                                text={` - ${details}`}
                              />
                            </Conditional>
                            <br />
                          </Fragment>
                        );
                      },
                    )}
                  </Conditional>
                  <Typography
                    display="inline"
                    text={formatDate(job.failedReason.failedAt, LONG_FORMAT)}
                  />
                  <Conditional condition={!!job.failedReason.userId}>
                    <Typography display="inline" text=" by " />
                    <Link to={`/users/${job.failedReason.userId}`}>
                      <StyledTooltipTypography
                        color="link"
                        display="inline"
                        text={job.failedReason.userName}
                      />
                    </Link>
                  </Conditional>
                </StyledInlineContainer>
              )}
            </span>
          </StyledJobDetailsCell>
          <StyledJobDetailsCell>
            <Typography text="Instruction" textStyle="bold" />
            <InstructionDataCell
              editing={viewInEditMode}
              instruction={viewInEditMode ? jobInstruction : job.instruction}
              onTextEdit={(text) => {
                setJobInstruction((prevInstruction) => {
                  return {
                    ...prevInstruction,
                    text,
                  };
                });
              }}
            />
          </StyledJobDetailsCell>
          <StyledJobDetailsCell>
            <Typography text="Job ID" textStyle="bold" />
            <Typography text={`#${job.id}`} />
          </StyledJobDetailsCell>
          <StyledJobDetailsCell>
            <Typography text="Step" textStyle="bold" />
            {viewInEditMode && isNonProductJob ? (
              <Dropdown
                items={(isProductJob
                  ? primaryStepsForJobs
                  : Object.keys(addJobTypeOptionsForJobStep)
                ).map((label) => {
                  return { label, value: label };
                })}
                value={jobStep}
                onSelect={(item) => {
                  const step = item?.value;

                  setJobStep(step);

                  const availableTypesForStep: JobType[] = isProductJob
                    ? jobTemplateTypeOptionsForJobStep[step]
                    : addJobTypeOptionsForJobStep[step];

                  if (
                    !availableTypesForStep.some((type) => {
                      return type === jobType;
                    })
                  ) {
                    setJobType(undefined);
                  }
                }}
              />
            ) : (
              <Typography text={job.step} />
            )}
          </StyledJobDetailsCell>
          <StyledJobDetailsCell>
            <Typography text="Type" textStyle="bold" />
            {viewInEditMode && isNonProductJob ? (
              <Dropdown
                items={(isProductJob
                  ? jobTemplateTypeOptionsForJobStep[jobStep]
                  : addJobTypeOptionsForJobStep[jobStep]
                ).map((label) => {
                  return { label, value: label };
                })}
                value={jobType}
                onSelect={(item) => {
                  setJobType(item?.value);
                }}
              />
            ) : (
              <Typography
                icon={
                  <Icon
                    color="t2"
                    name={jobTypeToIconTypeMap[job.type]}
                    top="2px"
                  />
                }
                style={{ left: '-5px' }}
                text={job.type}
              />
            )}
          </StyledJobDetailsCell>

          <StyledJobDetailsCell>
            <Typography text="Attempt" textStyle="bold" />
            <CellJobAttempt attempt={job.attempt} />
          </StyledJobDetailsCell>

          <Cell width={{ lg: 1, md: 2, sm: 1 }}>
            <StyledDivider />
          </Cell>
          <StyledJobDetailsCell>
            <Typography text="Assigned to" textStyle="bold" />
            <CellAssignedTo
              isEditing={viewInEditMode && isNonProductJob && !isRepairJob}
              job={job}
              setAssignedTo={(value) => {
                setAssignedTo(value || null);
              }}
              userNames={userNames}
            />
          </StyledJobDetailsCell>
          <StyledJobDetailsCell>
            <Typography text="Product" textStyle="bold" />
            <Conditional
              condition={!!job?.product}
              Fallback={<NotApplicableText />}
            >
              <ShortProductSummary {...job.product} />
            </Conditional>
          </StyledJobDetailsCell>
          <StyledJobDetailsCell>
            <Typography text="Version" textStyle="bold" />
            <MinorVersionInfo {...job.versionInfo} />
          </StyledJobDetailsCell>
          <StyledJobDetailsCell>
            <Typography text="Part" textStyle="bold" />
            {!!job.part && (
              <div>
                <Flex alignItems="center" gap="x">
                  <RFIDCell partId={job.part.id} stockId={job.stockId} />
                  <StyledAnchor
                    text={job.part.name}
                    to={`${routePaths.parts}/${job.part.id}`}
                  />
                </Flex>
                <ThumbnailLink
                  alt={job.part.name}
                  bgSize="contain"
                  cursor={job.part.thumbnail ? 'pointer' : 'default'}
                  imageSize="10rem"
                  imageUrl={
                    job.part.thumbnail
                      ? job.part.thumbnail
                      : placeholderLargeImgSrc
                  }
                  onClick={showPartThumbnailModal}
                />
              </div>
            )}
          </StyledJobDetailsCell>

          <StyledJobDetailsCell>
            <Typography text="Part ID" textStyle="bold" />

            {!!job.part && (
              <Link to={`${routePaths.parts}/${job.part.id}`}>
                <Typography
                  color="link"
                  decoration="underline"
                  text={`#${job.part.id}`}
                />
              </Link>
            )}
          </StyledJobDetailsCell>
          <StyledJobDetailsCell>
            <Typography text="Color" textStyle="bold" />
            <Conditional
              condition={partColorCode && partColorCode !== unpaintedColor.code}
            >
              <Grid alignItems="center" columns="max-content 1fr" gap="0.7rem">
                <ColorPickerItem
                  color={{
                    code: productColorsMap[partColorCode]?.code,
                    name: productColorsMap[partColorCode]?.shortColorName,
                  }}
                  size="2rem"
                />
                <Typography
                  text={productColorsMap[partColorCode]?.shortColorName}
                />
              </Grid>
            </Conditional>
          </StyledJobDetailsCell>

          <StyledJobDetailsCell>
            <Typography text="Material" textStyle="bold" />
            <Typography text={job?.part?.material} />
          </StyledJobDetailsCell>

          <StyledJobDetailsCell>
            <Typography text="Dur (m)" textStyle="bold" />
            {viewInEditMode ? (
              <TextField
                placeholder="Dur (m)"
                type="number"
                value={String(innerDuration)}
                onTextChange={(value) => {
                  return setInnerDuration(+value);
                }}
              />
            ) : (
              <Typography text={job.duration} />
            )}
          </StyledJobDetailsCell>

          <StyledJobDetailsCell>
            <Typography text="Machine" textStyle="bold" />
            {!!job.machine && <MachineDataCell machine={job.machine} />}
          </StyledJobDetailsCell>

          <StyledJobDetailsCell>
            <Typography text="G-code" textStyle="bold" />
            <GcodeDataCell gcode={job.gcode} />
          </StyledJobDetailsCell>

          <StyledJobDetailsCell>
            <Typography text="Weight" textStyle="bold" />
            <Typography text={job.weight && `${job.weight} g`} />
          </StyledJobDetailsCell>

          <StyledJobDetailsCell>
            <Typography text="Machine Type" textStyle="bold" />
            <MachineTypeSection
              job={job}
              machineType={machineType}
              setMachineType={setMachineType}
              viewInEditMode={viewInEditMode}
            />
          </StyledJobDetailsCell>

          <StyledJobDetailsCell>
            <Typography text="Machine Issue" textStyle="bold" />
            <MachineIssues issues={job.machineIssues} />
          </StyledJobDetailsCell>

          <StyledJobDetailsCell>
            <Typography text="Handouts" textStyle="bold" />
            <div>
              {job?.product?.handouts?.map((handout) => {
                return (
                  <a
                    key={handout.id}
                    href={handout.link}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Typography
                      color="link"
                      icon={<Icon color="link" name="arrows:arrow_external" />}
                      iconPosition="right"
                      text={handout.name}
                    />
                  </a>
                );
              })}
            </div>
          </StyledJobDetailsCell>

          <StyledJobDetailsCell>
            <Typography text="Inventories" textStyle="bold" />
            <InventoryDataCell
              editing={viewInEditMode}
              inventoryRequests={jobInventory}
              onEdit={setJobInventory}
            />
          </StyledJobDetailsCell>

          <StyledJobDetailsCell>
            <Typography text="Stock #" textStyle="bold" />
            {job.stockId ? (
              <Link to={`/stocks/${job.stockId}`}>
                <StyledStockWithPriority>
                  <Typography color="link" text={`#${job.stockId}`} />
                  {job.highPriority && (
                    <Tooltip description="High-priority stock" position="top">
                      <Icon name="arrows:arrow_triangle_up" top="2px" />
                    </Tooltip>
                  )}
                </StyledStockWithPriority>
              </Link>
            ) : (
              <NotApplicableText />
            )}
          </StyledJobDetailsCell>

          <StyledJobDetailsCell>
            <Typography text="Order #" textStyle="bold" />
            {job.order?.id ? (
              <Link to={`/orders/${job.order.id}`}>
                <Typography
                  color="link"
                  text={`${job.order.type} #${job.order.id}`}
                />
              </Link>
            ) : (
              <NotApplicableText />
            )}
          </StyledJobDetailsCell>

          <StyledJobDetailsCell>
            <Typography text="Created" textStyle="bold" />
            {!!job.statusInfo?.created && (
              <StyledInlineContainer>
                <Typography
                  text={`${formatDate(
                    job.statusInfo?.created.date,
                    LONG_FORMAT,
                  )}`}
                  variant="p2"
                />
                {!!job.statusInfo?.created?.user?.id && (
                  <StyledInlineContainer>
                    <Typography text=" by " variant="p2" />
                    <Link
                      to={`${routePaths.users}/${job.statusInfo?.created?.user?.id}`}
                    >
                      <Typography
                        color="link"
                        text={`${job.statusInfo?.created?.user.firstName} ${job.statusInfo?.created?.user.lastName}`}
                      />
                    </Link>
                  </StyledInlineContainer>
                )}
              </StyledInlineContainer>
            )}
          </StyledJobDetailsCell>

          <StyledJobDetailsCell>
            <Typography text="Start" textStyle="bold" />
            {!!job.statusInfo?.started && (
              <StyledInlineContainer>
                <Typography
                  text={`${formatDate(
                    job.statusInfo?.started.date,
                    LONG_FORMAT,
                  )}`}
                  variant="p2"
                />
                {!!job.statusInfo?.started?.user?.id && (
                  <StyledInlineContainer>
                    <Typography text=" by " variant="p2" />
                    <Link
                      to={`${routePaths.users}/${job.statusInfo?.started?.user?.id}`}
                    >
                      <Typography
                        color="link"
                        text={`${job.statusInfo?.started?.user.firstName} ${job.statusInfo?.started?.user.lastName}`}
                      />
                    </Link>
                  </StyledInlineContainer>
                )}
              </StyledInlineContainer>
            )}
          </StyledJobDetailsCell>

          <StyledJobDetailsCell
            style={{ marginBottom: '4rem' }}
            width={{ lg: 1, md: 2, sm: 1 }}
          >
            <Typography text="End" textStyle="bold" />
            {!!job.statusInfo?.completed && (
              <StyledInlineContainer>
                <Typography
                  text={`${formatDate(
                    job.statusInfo?.completed.date,
                    LONG_FORMAT,
                  )}`}
                  variant="p2"
                />

                {!!job.statusInfo?.completed?.user?.id && (
                  <StyledInlineContainer>
                    <Typography text=" by " variant="p2" />
                    <Link
                      to={`${routePaths.users}/${job.statusInfo?.completed?.user?.id}`}
                    >
                      <Typography
                        color="link"
                        text={`${job.statusInfo?.completed?.user.firstName} ${job.statusInfo?.completed?.user.lastName}`}
                      />
                    </Link>
                  </StyledInlineContainer>
                )}
              </StyledInlineContainer>
            )}
          </StyledJobDetailsCell>

          <Cell style={{ marginBottom: '4rem' }}>
            <Typography marginBottom="1rem" text="Notes" textStyle="bold" />
            <Grid
              columns={
                viewInEditMode || editingNotes ? '1fr' : '1fr max-content'
              }
              gap="2rem"
            >
              {!(viewInEditMode || editingNotes) && (
                <Typography
                  htmlText={
                    job?.notes?.replaceAll('\n', '<br/>') ??
                    'No notes have been added yet'
                  }
                />
              )}

              {(viewInEditMode || editingNotes) && (
                <div>
                  <TextArea minRows={3} value={notes} onTextChange={setNotes} />

                  {editingNotes && (
                    <Grid
                      columns="repeat(2, max-content)"
                      gap="1rem"
                      justifyContent="end"
                      marginTop="1rem"
                    >
                      <Button
                        minWidth="4rem"
                        text="Cancel"
                        variant="secondary"
                        onClick={() => {
                          return viewInEditMode
                            ? toggleEditingMode()
                            : setEditingNotes(false);
                        }}
                      />
                      <Button
                        minWidth="4rem"
                        text="Save"
                        variant="secondary"
                        onClick={updateNotes}
                      />
                    </Grid>
                  )}
                </div>
              )}

              {!(viewInEditMode || editingNotes) && (
                <Button
                  minWidth="4rem"
                  text="Edit"
                  variant="secondary"
                  onClick={() => {
                    return setEditingNotes(true);
                  }}
                />
              )}
            </Grid>
          </Cell>

          <Cell>
            <Typography marginBottom="1rem" text=" Timeline" textStyle="bold" />
            <Timeline
              isTitleVisible={false}
              items={job.timelines || []}
              renderItem={TimelineItem}
            />
          </Cell>
        </Grid>
      )}
    </DetailsPanel>
  );
};
