import { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import { Box, Conditional, Typography } from 'gantri-components';
import {
  orderStatusesMap,
  shipmentStatuses,
  stockStatuses,
} from '../../../../../../../../constants/options';
import Modal from '../../../../../../../../components/modals';
import styles from '../../../styles.module.scss';
import { CancelOrRefundItemsProps } from './cancel-items.types';
import { transactionsApi } from '../../../../../../../../api';
import { useNotification } from '../../../../../../../../hooks/useNotification';
import { ShortProductSummary } from '../../../../../../../../components/common/short-product-summary';

export const CancelOrRefundItems: FC<CancelOrRefundItemsProps> = ({
  currentOrderData,
  onClose,
  setStatus,
  visible,
}) => {
  const { id: orderId, shipments } = currentOrderData;

  const { notify, onInterceptRequest } = useNotification();
  const [shipStocksSelection, setShipStocksSelection] = useState({});
  const [localConfModalVisible, setLocalConfModalVisible] = useState(false);
  const [confirmDisabled, setConfirmDisabled] = useState(true);

  useEffect(() => {
    let hasSelectedStock = false;

    Object.keys(shipStocksSelection).forEach((key) => {
      if (shipStocksSelection[key]) {
        hasSelectedStock = true;
      }
    });

    setConfirmDisabled(!hasSelectedStock);
  }, [shipStocksSelection]);

  const confModalText = (
    <span>
      Are you sure you want to cancel
      <br />
      these items?
    </span>
  );

  const hideConfirmation = () => {
    setLocalConfModalVisible(false);
  };

  const handleConfirm = () => {
    setLocalConfModalVisible(true);
  };

  const handleCancel = () => {
    setShipStocksSelection({});
    onClose();
  };

  const submitConfirmation = async () => {
    const selectedStockInfoIds = Object.keys(shipStocksSelection)
      .filter((stockInfoId) => {
        return shipStocksSelection[stockInfoId] === true;
      })
      .map((i) => {
        return parseInt(i, 10);
      });
    const refunds = [];

    shipments.forEach((shipment) => {
      return shipment.stocks.forEach((stock) => {
        if (selectedStockInfoIds.includes(stock.stockInfoId)) {
          refunds.push({
            id: stock.stockInfoId,
          });
        }
      });
    });

    setLocalConfModalVisible(false);

    await onInterceptRequest(async () => {
      try {
        const { data } = await transactionsApi.cancelOrRefundItems({
          refundItems: refunds,
          status: orderStatusesMap.cancelled,
          transactionId: orderId,
        });

        if (data.newTransaction && data.newTransaction.shipments.length) {
          notify(data.notice);
        }

        if (data.success) {
          setStatus(data.oldTransaction.status);
        }

        handleCancel();
      } catch (error) {
        const errorData = error.response.data;

        notify(errorData.notice || errorData.error);
      }
    });
  };

  const totalShipments = shipments && shipments.length;

  const renderShipments = () => {
    return shipments.map(
      ({ id: shipmentId, status: shipmentStatus, stocks: shipStocks }, i) => {
        const shipmentIdx = i + 1;
        const shipmentStocks = shipStocks.map(
          ({ product, status: stockStatus, stockInfoId }) => {
            const stockSelected = shipStocksSelection[stockInfoId];

            const handleStockClick = () => {
              setShipStocksSelection({
                ...shipStocksSelection,
                [`${stockInfoId}`]: !stockSelected,
              });
            };

            const stockStyles = cx(styles['stock-wrapper'], 'row-flex-start', {
              [`${styles['border-default']}`]: !stockSelected,
              [`${styles['border-selected']}`]: stockSelected,
            });

            const hideStockFromModal =
              stockStatus === stockStatuses.cancelled ||
              stockStatus === stockStatuses.refunded;

            return hideStockFromModal ? null : (
              <div
                key={stockInfoId}
                className={stockStyles}
                role="button"
                tabIndex={0}
                onClick={handleStockClick}
              >
                <Box padding="x">
                  <ShortProductSummary fetchSku={product.sku} />
                </Box>
              </div>
            );
          },
        );

        return (
          <Conditional
            key={shipmentId}
            condition={[
              shipmentStatuses.waiting,
              shipmentStatuses.inProgress,
              shipmentStatuses.readyToShip,
            ].some((status) => {
              return status === shipmentStatus;
            })}
          >
            <div>
              {!shipmentStocks ? null : (
                <div className={styles['shipment-section']}>
                  <Typography
                    text={`Shipment ${shipmentIdx}/${totalShipments}`}
                  />
                  {shipmentStocks}
                </div>
              )}
            </div>
          </Conditional>
        );
      },
    );
  };

  useEffect(() => {
    const shipmentStockInfoIdsMap = {};

    if (shipments.length) {
      shipments.forEach(({ stocks: shipStocks }) => {
        shipStocks.forEach(({ stockInfoId }) => {
          shipmentStockInfoIdsMap[stockInfoId] = false;
        });
      });
    }

    setShipStocksSelection(shipmentStockInfoIdsMap);
  }, []);

  return localConfModalVisible ? (
    <Modal
      text={confModalText}
      type="confirmation"
      visible={visible}
      onCancel={hideConfirmation}
      onConfirm={submitConfirmation}
    />
  ) : (
    <Modal
      confirmButtonArchetype="red"
      confirmDisabled={confirmDisabled}
      type="selection"
      visible={visible}
      onCancel={handleCancel}
      onClose={handleCancel}
      onConfirm={handleConfirm}
    >
      <Typography
        align="center"
        marginBottom="2rem"
        text="Cancel Items"
        variant="h4"
      />
      {renderShipments()}
    </Modal>
  );
};
