import { Button, Flex, useModal } from 'gantri-components';
import { useNavigate } from 'react-router-dom';
import { AddJobModal } from '../modals/add-job-modal';
import Protected from '../../../../components/common/protected/protected';
import { CreatePrintPrepareQueueModal } from '../modals/create-print-prepare-queue-modal';
import { PrintPrepareQueueModal } from '../modals/print-prepare-queue-modal';
import { useMoreMenu } from '../../../../hooks/use-more-menu';
import { ManageQueuesModal } from '../modals/manage-queues-modal';
import { JobsHeaderContentProps } from './jobs-header-content.types';
import { useGetDownloadMenuOption } from '../../../../hooks/use-get-download-menu-option';
import { jobsApi } from '../../../../api';
import { downloadColumns } from './jobs-header-content.constants';
import { userRoles } from '../../../../constants/users';
import routePaths from '../../../../config/route-paths';

export const JobsHeaderContent = (props: JobsHeaderContentProps) => {
  const {
    DownloadGcodeStatusToast,
    DownloadHandoutStatusToast,
    DownloadInstructionStatusToast,
    downloadFilters,
    reloadCurrentPage,
    totalResults,
    userNames,
  } = props;

  const navigate = useNavigate();

  const [showAddJobModal, hideAddJobModal] = useModal(() => {
    return (
      <AddJobModal
        userNames={userNames}
        onClose={hideAddJobModal}
        onUpdate={reloadCurrentPage}
      />
    );
  }, [userNames]);

  const [showCreatePrintPrepareQueueModal, hideCreatePrintPrepareQueueModal] =
    useModal(() => {
      return (
        <CreatePrintPrepareQueueModal
          onClose={hideCreatePrintPrepareQueueModal}
          onConfirm={showPrintPrepareQueueModal}
        />
      );
    }, []);

  const [showPrintPrepareQueueModal, hidePrintPrepareQueueModal] =
    useModal(() => {
      return (
        <PrintPrepareQueueModal
          onClose={hidePrintPrepareQueueModal}
          onUpdate={reloadCurrentPage}
        />
      );
    }, []);

  const [showManageQueuesModal, hideManageQueuesModal] = useModal(() => {
    return <ManageQueuesModal onClose={hideManageQueuesModal} />;
  }, []);

  const { Toast: DownloadCsvToast, downloadOption } = useGetDownloadMenuOption({
    columns: downloadColumns,
    fetchRequestWithFilters: (overrides) => {
      return jobsApi
        .getPaginatedJobs({ ...downloadFilters, ...overrides, isCsv: true })
        .then(({ data }) => {
          return data.jobs;
        });
    },
    fileNamePrefix: 'Jobs Admin',
    totalResults,
  });

  const { MoreMenu } = useMoreMenu({
    options: [
      {
        allowedFor: [userRoles.admin],
        name: 'Part Release Rate',
        onOptionClick: () => {
          navigate(routePaths.partReleaseRate);
        },
      },
      {
        allowedFor: [userRoles.admin],
        name: 'Manage queues',
        onOptionClick: showManageQueuesModal,
      },
      {
        ...downloadOption,
        allowedFor: [userRoles.lead, userRoles.admin],
      },
    ],
  });

  return (
    <>
      <Flex alignItems="center" gap=".5x">
        <Button
          text="Prepare Prints"
          variant="secondary"
          onClick={showCreatePrintPrepareQueueModal}
        />
        <Protected allowedFor={['Admin', 'Lead']}>
          <Button text="Add Job" onClick={showAddJobModal} />
          <MoreMenu />
          <DownloadCsvToast />
        </Protected>
      </Flex>
      <DownloadGcodeStatusToast position="bottom-end" />
      <DownloadHandoutStatusToast position="bottom-end" />
      <DownloadInstructionStatusToast maxWidth="25rem" position="bottom-end" />
    </>
  );
};
